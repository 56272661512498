<template>
  <div>
    <Spinner />
    <b-spinner v-if="!oneC" class="spinner" label="Large Spinner" />
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      :hide-goto-end-buttons="true"
      :limit="1"
      class="pogination"
    >
      <template #prev-text>
        <img
          class="pagination__btn-icon pagination__btn-icon_prev"
          src="@/assets/png/database-icon-active.png"
          alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>
    <div class="table">
      <b-table
        v-if="oneC"
        id="my-table"
        class="table__box"
        :borderless="true"
        :items="oneC"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(created_at)="data">
          <p>{{ new Date(data.item.created_at).toLocaleDateString() }}</p>
        </template>
        <template #cell(count)="data">
          <p>{{ data.item.count }}</p>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "@/components/spinner";

export default {
  components: { Spinner },
  data() {
    return {
      spinnerShow: true,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key: "created_at",
          label: "Дата",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        {
          key: "count",
          label: "Количество",
          sortable: true,
          isRowHeader: true,
          class: "tb-all tb-lg",
        },
        // {
        //   key: "download",
        //   label: "Скачать",
        //   sortable: true,
        //   isRowHeader: true,
        //   class: "tb-all tb-lg",
        // },
      ],
    };
  },
  computed: {
    rows() {
      if (this.oneC) {
        this.$emit("spinner");
        return this.oneC.length;
      }
      return 0;
    },
    ...mapState(["oneC"]),
  },
  created() {
    this.$store.dispatch("getOneC");
  },
  methods: {
    
  },
};
</script>

<style scoped>
.pogination {
  position: absolute;
  top: -82px;
  right: 0;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.report__export:hover {
  text-decoration: underline;
}
</style>
